<template>
  <div class="container oci-check-in-out-body">    
    <DCNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Items to deliver</b>
        </b-row>
        <b-row>
            <b-col>
            <b-form-group
                label=""
                label-for="waste-select"
            >
                <div v-for="(item, index) in bookingLines.filter(item => item.isVisible && !item.is_collection)" :key="index">
                <b-row class="align-items-center">
                  <b-col cols="12">
                    <b-form-group
                    :label="'Item - ' + item.type.category"
                    label-for="delivery-type"
                    class="mb-0"
                    >                                        
                      <b-form-select
                      :id="'delivery-type-select-' + index"
                      v-model="item.type.id"
                      :options="deleveryItemOptions"
                      placeholder="Choose a item type"
                      :disabled="isInputDisabled"
                      @change="updateTrackableByQuantity(item)"
                      ></b-form-select>                      
                    </b-form-group>  
                  </b-col>                
                </b-row>
                <b-row class="align-items-center">
                  <b-col class="col-7">
                    <b-form-group
                    label="Quantity"
                    label-for="waste-bins"
                    v-if="item.type.trackable_by_quantity"
                    >                                        
                      <b-form-input
                      v-model="item.quantity"
                      placeholder=""
                      name="name-edit"
                      :disabled="isInputDisabled"
                      />                      
                    </b-form-group>  
                    <b-form-group
                    label="Note"
                    label-for="waste-supervisor"
                    v-if="!item.type.trackable_by_quantity"
                    >                                        
                      <b-form-input
                      v-model="item.notes"
                      placeholder=""
                      name="name-edit"
                      :disabled="isInputDisabled"
                      />                      
                    </b-form-group>                     
                  </b-col>
                  <b-col class="col-2 oci-projects-button-container">
                    <b-button
                      type="submit"
                      @click="removeItem(item.id)"
                      :disabled="isInputDisabled"
                    >
                      -
                    </b-button>
                  </b-col>
                  <b-col class="col-2 oci-check-in-button-container">
                    <b-button
                      type="submit"
                      @click="addItem"
                      :disabled="isInputDisabled"
                    >
                      +
                    </b-button>
                  </b-col>
                </b-row>
                </div>                
            </b-form-group>
            </b-col>
        </b-row> 
        
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import DCNumberView from '@/views/DCNumberView.vue'
import { generalMethods } from '@/components/General-Functions'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    DCNumberView,
  },
  data() {
    return {
      deleveryItemOptions: [],
    };
  },   
  created() {
    this.deleveryItemOptions = this.dcLineTypes.map((lineType) => ({
      value: lineType.id,
      text: lineType.name,
    }));
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),    
    ...mapGetters({ bookingLines: 'app/getBookingLines' }),
    ...mapGetters({ dcLineTypes: 'app/getDCLineTypes' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }
  }, 
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    async GoToNextPage() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {        
        for (const item of this.bookingLines) {        
          if ((item.quantity <= 0 && item.type.trackable_by_quantity) || (item.notes === '' && !item.type.trackable_by_quantity) || item.type === null || item.type.id === null || item.type.id < 0) {
            if (item.isVisible && !item.is_collection) {
              generalMethods.messageBox('', 'Please set a quantity/note and type for each item.', 'warning');
              return;
            }
            if (!item.is_collection) {
              item.isVisible = false;
            }
          }          
        }
        console.log('before ', this.bookingLines);
        await bookingsMethods.saveBookingLines(this.currentBooking.id, this.bookingLines, false);
      }      
      console.log('before router');
      this.$router.push('/confirm_delivery_dc');
    },
    addItem() {
      const newItem = {
          id: -1,
          quantity: 0,
          notes: '',
          is_collection: false,
          type: {id:-1, name:'', category:'', trackable_by_quantity:null},
          isVisible: true,
      };      
      this.bookingLines.push(newItem);
      this.$forceUpdate();
    },
    removeItem(itemId) {
      console.log('removeItem', itemId);
      const itemIndex = this.bookingLines.findIndex(item => item.id === itemId && item.isVisible && !item.is_collection);
      if (itemIndex === -1) {
        console.warn('Item not found.');
        return;
      }
      const visibleItemsCount = this.bookingLines.filter(item => item.isVisible && !item.is_collection).length;
      console.log('Visible items count:', visibleItemsCount);
      if (visibleItemsCount > 1) {
        this.bookingLines[itemIndex].isVisible = false;
        if (this.bookingLines[itemIndex].id < 0) {
          this.bookingLines[itemIndex].bins = -1;
        }
      } else {
        console.warn('Cannot remove the last visible item.');
      }
      this.$forceUpdate();
    },
    updateTrackableByQuantity(item) {
      const selectedType = this.dcLineTypes.find(type => type.id === item.type.id);
      if (selectedType) {
        item.type.trackable_by_quantity = selectedType.trackable_by_quantity;
        item.type.category = selectedType.category;
      } else {
        item.type.trackable_by_quantity = false; // or some default value
        item.type.category = null;
      }
      this.$forceUpdate();
    },    
  },  
}
</script>
